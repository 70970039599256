<template>
  <li
    class="accordion__item"
    :class="borderless && 'borderless'"
  >
    <div
      class="cursor-pointer"
      :class="{'accordion__trigger_active': visible}"
      @click="open"
    >
      <div class="flex justify-between">
        <slot name="trigger">
          <span>{{ visible ? 'close' : 'open' }}</span>
        </slot>

        <svg
          v-if="chevron"
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          class="transition-all duration-200 flex-shrink-0"
          :class="{ 'rotate-180': visible }"
        >
          <path
            d="M24.3137 16.6569L18.6569 22.3137L13 16.6569"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <Transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="visible"
        class="accordion__content"
      >
        <slot />
      </div>
    </Transition>
  </li>
</template>

<script setup lang="ts">
import { inject, ref, computed } from 'vue';
import type { BaseAccordionData } from '.';

interface Props {
  borderless?: boolean
  chevron?: boolean
}

withDefaults(defineProps<Props>(), {
  borderless: false,
  chevron: true,
});

const Accordion = inject('Accordion') as BaseAccordionData;
const index = ref(Accordion.count++);
const visible = computed(() => Accordion.active === index.value);

// eslint-disable-next-line func-call-spacing
const emits = defineEmits<{
  (e: 'change', active: number | null): void
}>();

function open () {
  if (visible.value) {
    Accordion.active = null;
  } else {
    Accordion.active = index.value;
  }
  emits('change', Accordion.active);
}
function start (el: Element) {
  const node = el as HTMLElement;
  node.style.height = el.scrollHeight + 'px';
}
function end (el: Element) {
  const node = el as HTMLElement;
  node.style.height = '';
}
</script>

<style scoped>
.accordion__item {
  @apply relative px-0 py-4;
  &:not(.borderless) {
    @apply border-b border-brand-secondary/10 last:border-none
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
