<template>
  <ul class="accordion">
    <slot />
  </ul>
</template>

<script setup lang="ts">
import { provide, reactive, watch } from 'vue';
import type { BaseAccordionData } from '.';

const data = reactive<BaseAccordionData>({
  count: 0,
  active: null,
});

interface Props {
  activeIndex?: number | null
}

const props = withDefaults(defineProps<Props>(), {
  activeIndex: null,
});

watch(() => props.activeIndex, (newVal) => {
  data.active = newVal;
}, { immediate: true });

provide('Accordion', data);
</script>

<style scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
